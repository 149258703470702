import { Component, OnInit } from '@angular/core';
import { ApiService, AuthService } from 'src/app/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExportToCsv } from 'export-to-csv';
import { User } from './../../models/user.model';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { DoubleConfirmationDialogService } from 'src/app/services/double-confirmation-dialog.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  
  users: User[] = [];
  count: number = 0;
  page: number = 1;

  searchParams = {
    limit: 10,
    skip: 0,
    keyword: '',
    sortColumn: 'name',
    sortAsc: true
  };

  columns = [
    { 'name': 'Name', 'field': 'name', 'sortable': true },
    { 'name': 'Surname', 'field': 'surname', 'sortable': true },
    { 'name': 'Email', 'field': 'email', 'sortable': true },
    { 'name': 'Created', 'field': 'createdAt', 'sortable': true },
    { 'name': 'Ads', 'field': 'commercialOk', 'sortable': true },
    { 'name': 'Platforms', 'field': 'platforms', 'sortable': false },
    { 'name': 'Company', 'field': 'companyName', 'sortable': true },
    { 'name': 'Country', 'field': 'country', 'sortable': true },
    { 'name': 'Verified', 'field': 'accountVerified', 'sortable': true },
    { 'name': 'Actions', 'field': null, 'sortable': false }
  ];
  constructor(private apiService: ApiService,
    private authService: AuthService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private doubleConfirmationDialogService: DoubleConfirmationDialogService,
    private router: Router) { }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(){
    this.apiService.getUsers(this.searchParams.limit, this.searchParams.skip, this.searchParams.keyword, this.searchParams.sortColumn, this.searchParams.sortAsc).subscribe((res) => {
      if (res['status']) {
        this.users = res.data.users;
        this.count = res.data.count;
        this.initCreatedAtDates();
      }
    });
  }

  initCreatedAtDates() {
    for (const user of this.users) {
      user.createdAtStr = new Date(user.createdAt).toISOString();
    }
  }

  onSizeChange() {
    this.page = 1;
    this.searchParams.skip = 0;
    this.loadUsers();
  }

  onPageChange() {
    this.searchParams.skip = this.searchParams.limit * (this.page - 1);
    this.loadUsers();
  }

  launchSearch(){
    this.searchParams.keyword = this.searchParams.keyword.trim();
    this.page = 1;
    this.searchParams.skip = 0;
    this.loadUsers();
  }

  clearSearch(){
    this.searchParams.keyword = '';
    this.loadUsers();
  }

  toggleSortable(column){
    if (column.field === this.searchParams.sortColumn) this.searchParams.sortAsc = !this.searchParams.sortAsc;
    else {
      this.searchParams.sortColumn = column.field;
      this.searchParams.sortAsc = true;
    }
    this.page = 1;
    this.searchParams.skip = 0;
    this.loadUsers();
  }

  editUser(user: User) {
    this.router.navigate(['/users/edit'], { queryParams: { id: user.id }});
  }

  deleteUser(user: User) {
    this.doubleConfirmationDialogService
      .confirm('Delete user', 
        'Do you really want to delete the user?', 
        'user\'s name',
        user.name)
      .then((confirmed) => {
        if (confirmed)
          this.apiService.deleteUser(user.id).subscribe((res) => {
            this.toastr.success("User deleted successfully")
            this.loadUsers();
          }, error => {
            this.toastr.error("Unable to delete user")
          })
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  associatedDevices(id: string) {
    this.router.navigate(['/devices', { userId: id }]);
  }

  export() {
    this.apiService.exportData().subscribe(res => {
      if (res['status']) {
        const options = {
          fieldSeparator: ',',
          filename: `salicru_users_${new Date().toLocaleString()}.csv`,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Salicru Users - Commercial info agreed',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        const dataToExport = res['data']
        csvExporter.generateCsv(dataToExport);
      }
    });
  }

  verifyUser(user: User) {
    this.confirmationDialogService.confirm('Verify user', 'Do you really want to manually verify this user?')
      .then((confirmed) => {
        if (confirmed) {
          user.accountVerified = true;
          this.apiService.updateSalicruUser(user).subscribe(res => {
            if (res) {
              this.toastr.success("User verified successfully")
            }
          }, error => {
            user.accountVerified = false;
            this.toastr.error("Unable to verify user")
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}